<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visible"
    @close="handleEditDialogClose"
    style="overflow: hidden"
    top="20px"
  >
    <div style="overflow-y: auto; height: 70vh">
      <el-form
        :model="formData"
        label-width="130px"
        :rules="formRule"
        ref="form"
        size="mini"
        class="locale-form"
      >
        <el-row class="form-part">
          <el-col :span="3" class="form-part-title">
            <div class="wordWrapClass">基本信息</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="name" label="监测点名称">
              <el-input
                placeholder="请输入监测点名称"
                @change="hasChange"
                v-model.trim="formData.name"
                :disabled="isView"/>
            </el-form-item>
            <el-form-item prop="area_id" label="所属地区">
              <el-cascader
                style="width: 100%"
                placeholder="关键字"
                v-model.trim="formData.area_id"
                @change="hasChange"
                :options="areaTree"
                :props="areaProps"
                filterable
                :disabled="isView"
              />
            </el-form-item>
            <template v-if="formData.type == 1">
            <el-form-item prop="lng" label="经度">
              <el-input
                @change="hasChange"
                placeholder="可直接粘贴到此处"
                v-model.trim="formData.lng"
                @keyup.ctrl.86.native="handlePaste"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="stove_length" label="集气罩长">
              <el-input
                @change="hasChange"
                placeholder="请输入集气罩长(m)"
                v-model.number="formData.stove_length"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="stove_area" label="集气灶面积">
              <el-input
                @change="hasChange"
                placeholder="请输入集气灶面积(m²)"
                v-model.trim="formData.stove_area"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="cuisine" label="选择菜系">
              <el-cascader
                :disabled="isView"
                v-model="formData.cuisine"
                :options="cuisine"
                @change="hasChange"></el-cascader>
            </el-form-item>
            </template>
            <el-form-item prop="scale" label="企业类型">
              <el-select
                style="width: 100%"
                @change="hasChange"
                placeholder="请选择企业类型"
                v-model.trim="formData.scale"
                :disabled="isView">
                <el-option value="大" label="大"/>
                <el-option value="中" label="中"/>
                <el-option value="小" label="小"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="status_of_record" label="监测点状态">
              <el-radio-group
                @input="statusChange"
                v-model="formData.status_name"
                size="mini">
                <el-radio-button label="正常"/>
                <el-radio-button label="下线"/>
                <el-radio-button label="歇业"/>
              </el-radio-group>
            </el-form-item>
            <template v-if="formData.type == 1">
            <el-form-item label="坐标拾取">
              <a
                style=" color: #409eff; text-decoration: none;"
                target="_blank"
                v-if="!isView"
                href="https://api.map.baidu.com/lbsapi/getpoint/index.html">
                百度坐标拾取系统
              </a>
            </el-form-item>
            </template>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="addr" label="详细地址">
              <el-input
                @change="hasChange"
                placeholder="请输入详细地址"
                v-model.trim="formData.addr"
                :disabled="isView"/>
            </el-form-item>
            <el-form-item prop="owner" label="所属单位">
              <el-cascader
                style="width: 100%"
                placeholder="关键字"
                v-model.trim="formData.owner"
                @change="handleOwnerChange"
                :options="customerTree"
                :props="props"
                filterable
                :disabled="isView"
              />
            </el-form-item>
            <template v-if="formData.type == 1">
            <el-form-item prop="lat" label="纬度">
              <el-input
                @change="hasChange"
                v-model.trim="formData.lat"
                :disabled="isView"/>
            </el-form-item>
            <el-form-item prop="stove_width" label="集气罩宽">
              <el-input
                @change="hasChange"
                placeholder="请输入集气罩宽"
                v-model.number="formData.stove_width"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="exhaust_time" label="日均排烟时间">
              <el-input
                @change="hasChange"
                placeholder="请输入日均排烟时间(h)"
                v-model.trim="formData.exhaust_time"
                :disabled="isView"
              />
            </el-form-item>
            </template>
            <el-form-item prop="stove_num" label="基准灶头数">
              <el-input
                @change="hasChange"
                placeholder="请输入基准灶头数"
                v-model.number="formData.stove_num"
                :disabled="isView"
              />
            </el-form-item>
            <el-form-item prop="management_types" label="经营类型">
              <el-select
                style="width: 100%"
                @change="hasChange"
                placeholder="请选择经营类型"
                :disabled="isView"
                v-model.trim="formData.management_types">
                <el-option value="经营型" label="经营型"/>
                <el-option value="非经营型" label="非经营型"/>
              </el-select>
            </el-form-item>
            <template v-if="formData.type == 1">
            <el-form-item prop="locale_type" label="监测类型">
              <el-select v-model="formData.locale_type" placeholder="请选择监测类型">
                <el-option-group
                  v-for="group in localeTypes"
                  :key="group.label"
                  :label="group.label">
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
            </template>
            <template v-if="title == '编辑'">
              <el-form-item prop="filter_wash_day" label="净化器清洗天数">
                <el-input
                  @change="hasChange"
                  placeholder="请输入净化器清洗天数"
                  v-model.number="formData.filter_wash_day"
                  :disabled="isView"
                />
              </el-form-item>
            </template>
          </el-col>
        </el-row>
        <template v-if="formData.type == 1">
        <el-row class="form-part">
          <el-col :span="3" class="form-part-title">
            <div class="wordWrapClass">排放系统信息</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="fan_brand" label="风机品牌">
              <el-input
                @change="hasChange"
                v-model.trim="formData.fan_brand"
                :disabled="isView"
                placeholder="请输入风机品牌"/>
            </el-form-item>
            <el-form-item prop="fan_power" label="风机功率">
              <el-input
                @change="hasChange"
                v-model.number="formData.fan_power"
                :disabled="isView"
                placeholder="请输入风机功率(kW)"/>
            </el-form-item>
            <el-form-item prop="fan_speed" label="烟气流速">
              <el-input
                @change="hasChange"
                v-model.number="formData.fan_speed"
                :disabled="isView"
                placeholder="请输入风机功率(m/s)"/>
            </el-form-item>
            <el-form-item prop="filter_brand" label="净化器品牌">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filter_brand"
                :disabled="isView"
                placeholder="请输入净化器品牌"/>
            </el-form-item>
            <el-form-item prop="purifying_rate" label="净化器净化率">
              <el-input
                @change="hasChange"
                v-model.number="formData.purifying_rate"
                :disabled="isView"
                placeholder="请输入净化器净化率"/>
            </el-form-item>
            <el-form-item prop="filter_power" label="净化器功率">
              <el-input
                @change="hasChange"
                v-model.number="formData.filter_power"
                :disabled="isView"
                placeholder="请输入净化器功率(kW)"/>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item prop="fan_model" label="风机型号">
              <el-input
                @change="hasChange"
                v-model.trim="formData.fan_model"
                :disabled="isView"
                placeholder="请输入风机型号"/>
            </el-form-item>
            <el-form-item prop="fan_standard_current" label="风机标准电流">
              <el-input
                @change="hasChange"
                v-model.trim="formData.fan_standard_current"
                :disabled="isView"
                placeholder="请输入风机标准电流(A)"/>
            </el-form-item>
            <el-form-item prop="pipe_area" label="管道截面积">
              <el-input
                @change="hasChange"
                v-model.number="formData.pipe_area"
                :disabled="isView"
                placeholder="请输入管道截面积(m²)"/>
            </el-form-item>
            <el-form-item prop="filter_model" label="净化器型号">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filter_model"
                :disabled="isView"
                placeholder="请输入净化器型号"/>
            </el-form-item>
            <el-form-item prop="fan_quantity" label="净化器风量">
              <el-input
                @change="hasChange"
                v-model.number="formData.fan_quantity"
                :disabled="isView"
                placeholder="请输入净化器风量(m³/h)"/>
            </el-form-item>
            <el-form-item prop="filter_standard_current" label="净化器标准电流">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filter_standard_current"
                :disabled="isView"
                placeholder="请输入净化器标准电流(A)"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="form-part">
          <el-col :span="3" class="form-part-title">
            <div class="wordWrapClass">监测仪信息</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="voc_factory_name" label="监测仪品牌">
              <el-select
                style="width: 100%"
                @change="handleFactorySelect"
                v-model.trim="formData.voc_factory_name"
                :disabled="isView"
                placeholder="请选择监测仪品牌">
                <el-option v-for="factory in factoryList"
                           :key="factory.factory_name"
                           :label="factory.factory_name"
                           :value="factory.factory_name"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="principle" label="检测原理">
              <el-select
                @change="hasChange"
                v-model.trim="formData.principle"
                :disabled="isView"
                placeholder="请输入检测原理"
                style="width: 100%">
                <el-option value="光散射" label="光散射"/>
                <el-option value="电化学" label="电化学"/>
                <el-option value="其他" label="其他"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="power_supply_mode" label="接电方式">
              <el-select
                @change="hasChange"
                v-model.trim="formData.power_supply_mode"
                :disabled="isView"
                placeholder="请输入接电方式"
                style="width: 100%">
                <el-option value="风机取电" label="风机取电"/>
                <el-option value="净化器取电" label="净化器取电"/>
                <el-option value="市电" label="市电"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="fan_mn" label="风机监控编码"
                          v-if="(this.formData.send_mode===2 ||(this.formData.send_mode===3 &&this.formData.power_supply_mode ==='净化器取电'))&&this.title ==='新增'">
              <el-input
                @change="hasChange"
                v-model.trim="formData.fan_mn"
                :disabled="isView"
                placeholder="请输入风机监控编码"/>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="voc_factory_model_id" label="监测仪型号">
              <el-select
                style="width: 100%"
                @change="handleFactoryModelChange"
                v-model="formData.voc_factory_model_id"
                :disabled="isView"
                placeholder="请选择监测仪型号">
                <el-option v-for="(item, index) in factoryModelList"
                  :key="index"
                  :value="item.id"
                  :label="item.model_name"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="send_mode" label="传输方式">
              <el-select
                @change="hasChange"
                v-model.number="formData.send_mode"
                :disabled="isView"
                placeholder="请输入传输方式"
                style="width: 100%">
                <el-option :value="1" label="合并传输"/>
                <el-option :value="2" label="独立传输"/>
                <el-option :value="3" label="两路传输"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="voc_mn" label="监测仪编码" v-if="this.title ==='新增'">
              <el-input
                @change="hasChange"
                v-model.trim="formData.voc_mn"
                :disabled="isView"
                placeholder="请输入监测仪编码"/>
            </el-form-item>
            <el-form-item prop="filter_mn" label="净化器监控编码"
                          v-if="(this.formData.send_mode===2 ||(this.formData.send_mode===3 &&this.formData.power_supply_mode ==='风机取电') ||(this.formData.send_mode===3 &&this.formData.power_supply_mode ==='市电'))&&this.title ==='新增' ">
              <el-input
                @change="hasChange"
                v-model.trim="formData.filter_mn"
                :disabled="isView"
                placeholder="请输入净化器监控编码"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-part">
          <el-col :span="3" class="form-part-title">
            <div class="wordWrapClass">判断参数</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="emissions_sill" label="油烟阈值">
              <el-input
                @change="hasChange"
                v-model.trim="formData.emissions_sill"
                :disabled="isView"
                placeholder="默认值为2"/>
            </el-form-item>
            <el-form-item prop="hydrocarbon_sill" label="非甲烷阈值">
              <el-input
                @change="hasChange"
                v-model.trim="formData.hydrocarbon_sill"
                :disabled="isView"
                placeholder="默认值为10"/>
            </el-form-item>
            <el-form-item prop="samplings" label="抽样次数">
              <el-input
                @change="hasChange"
                v-model.trim="formData.samplings"
                :disabled="isView"
                placeholder="默认值为3"/>
            </el-form-item>
            <el-form-item prop="fan_status" label="风机状态">
              <el-radio-group v-model="formData.fan_status">
                <el-radio :label="1">正常</el-radio>
                <el-radio :label="0">不正常</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="granule_sill" label="颗粒物阈值">
              <el-input
                @change="hasChange"
                v-model.trim="formData.granule_sill"
                :disabled="isView"
                placeholder="默认值为5"/>
            </el-form-item>
            <el-form-item prop="offline_judge" label="异常判定">
              <el-input
                @change="hasChange"
                v-model.number="formData.offline_judge"
                :disabled="isView"
                placeholder="默认值为48"/>
            </el-form-item>
            <el-form-item prop="surpass_calc_method" label="折算浓度计算方式">
              <el-select
                style="width: 100%"
                @change="hasChange"
                v-model.trim="formData.surpass_calc_method"
                :disabled="isView"
                placeholder="请输入折算浓度计算方式">
                <el-option :value="1" label="抽样计算"/>
                <el-option :value="2" label="滑动计算"/>
              </el-select>
            </el-form-item>
            <el-form-item prop="filter_status" label="净化器状态">
              <el-radio-group v-model="formData.filter_status">
                <el-radio :label="1">正常</el-radio>
                <el-radio :label="0">不正常</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-part">
          <el-col :span="3" class="form-part-title">
            <div class="wordWrapClass">上传照片</div>
          </el-col>
          <el-col :span="20">
            <el-form-item>
              <el-upload
                ref="upload"
                accept=".jpg,.jpeg,.png"
                :show-file-list="false"
                :action="upload()"
                :on-success="handleAvatarSuccess"
                :before-upload="hasChange"
                v-if="!isView"
              >
                <i class="el-icon-plus avatar-uploader-icon"/>
                <div slot="tip">（支持jpg、jpeg、png）</div>
              </el-upload>
              <div class="img-list" v-if="fileList">
                <div class="single-img" v-for="(item, idx) in fileList" :key="idx"
                     style="position: relative; display: inline-block; margin-right: 10px;">
                  <el-image
                    style="width: 100px;height: 100px"
                    :src="item"
                    fit="fit"
                    :preview-src-list="fileList"/>
                  <div v-if="!isView" class="remove-img el-icon-close" @click="removeImg(idx)"></div>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        </template>
        <el-row class="form-part" v-if="isView && title ==='审核'">
          <el-col :span="8">
            <el-form-item label="审核结果">
              <el-select
                @change="hasChange"
                v-model.number="status">
                <el-option :value="1" label="通过"/>
                <el-option :value="2" label="不通过"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item
              label="原因">
              <el-input
                @input="hasChange"
                v-model="formData.remark"
                placeholder="请输入原因"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click.native="handleClose"
      >取消
      </el-button
      >
      <el-button type="primary" size="mini" @click.native="handleSubmit"
      >提交
      </el-button
      >
    </div>
  </el-dialog>
</template>
<script>

import conf from "@/config";
import {mapState} from "vuex";
import {getSPname, getUserInfo, join, loadData, ownerJoin, stg} from "@/util";

export default {
  name: "LocaleEditDialog",
  props: ['title', 'visible', 'state', 'formData', 'isView'],
  computed: {
    ...mapState(['areaTree', 'clientHeight', 'locale', 'customerTree']),
    ...mapState({
      props: (state) => Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
      selAreaProps: (state) =>
        Object.assign({}, state.props, {checkStrictly: true}),
      areaProps: (state) => Object.assign({}, state.props)
    })
  },
  data() {
    return {
      userInfo: '',
      changeState: false,
      cuisine: [],
      fileList: [],
      formRule: {
        name: [{required: true, message: '名称不可为空', trigger: 'blur'}],
        contact: [{required: true, message: '联系人不可为空', trigger: 'blur'}],
        addr: [{required: true, message: '详细地址不可为空', trigger: 'blur'}],
        mobile: [{required: true, message: '联系电话不可为空', trigger: 'blur'}],
        lng: [{required: true, message: '经度不可为空', trigger: 'blur'}],
        lat: [{required: true, message: '纬度不可为空', trigger: 'blur'}],
        area_id: [{required: true, message: '所属地区不可为空', trigger: 'blur'}],
        owner: [{required: true, message: '所属单位不可为空', trigger: 'blur'}],
        stove_area: [{required: true, message: '集气罩面积不可为空', trigger: 'blur'},,{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        exhaust_time: [{required: true, message: '日均排烟时间不可为空', trigger: 'blur'}],
        cuisine: [{required: true, message: '菜系不可为空', trigger: 'blur'}],
        stove_num: [{required: true, message: '基准灶头数不可为空', trigger: 'blur'}],
        scale: [{required: true, message: '企业类型不可为空', trigger: 'blur'}],
        management_types: [{required: true, message: '经营类型不可为空', trigger: 'blur'}],
        fan_standard_current: [{required: true, message: '风机标准电流不可为空', trigger: 'blur'},{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        filter_standard_current: [{required: true, message: '净化器标准电流不可为空', trigger: 'blur'},{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }],
        voc_factory_name: [{required: true, message: '监测仪品牌不可为空', trigger: 'blur'}],
        principle: [{required: true, message: '检测原理不可为空', trigger: 'blur'}],
        power_supply_mode: [{required: true, message: '接电方式不可为空', trigger: 'blur'}],
        voc_factory_model_id: [{required: true, message: '监测仪型号不可为空', trigger: 'blur'}],
        send_mode: [{required: true, message: '传输方式不可为空', trigger: 'blur'}],
        voc_mn: [{required: true, message: '监测仪编码', trigger: 'blur'}],
        filter_wash_day: [{required: true, message: '净化器清洗天数不可为空', trigger: 'blur'},,{ pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式' }],
        locale_type: [{required: true, message: '监测点类型不能为空', trigger: 'blur'},{ pattern: /^([1-9][0-9]*)$/, message: '请选择正确格式' }],
      },
      factoryList: [],
      factoryModelList: [],
      maintenanceList: [],
      localeTypes: [],
      status: null
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    "formData.locale_pics": {
      handler: function (val) {
        this.fileList = []
        if (val) {
          this.fileList = Array.from(this.formData.locale_pics)
        } else {
          this.fileList = []
        }
      }
    },
    title: {
      handler: function (val) {
        if (val === '新增') {
          this.fileList = []
        }
      }
    },
    "formData.voc_factory_name": {
      handler: function (val) {
        let param = {
          StartAt: 0,
          Size: 20,
          Param:
            {
              FactoryName: val,
              ModelType: 1
            }
        }
        this.$post('admin/listFactoryModel', param).then(res => {
          this.factoryModelList = res.content
        })
      }
    }
  },
  methods: {
    init() {
      this.fileList = []
      const user = getUserInfo()
      this.userInfo = user.Info
      this.$get('admin/listAllCuisine').then((res) => {
        var data = []
        var keys = []
        for (var i = 0; i < res.length; i++) {
          var index = keys.indexOf(res[i].ResourceName)
          if (index == -1) {
            keys.push(res[i].ResourceName)
            data.push({
              value: res[i].ResourceName,
              label: res[i].ResourceName,
              children:[{
                value: res[i].Id,
                label: res[i].Name,
              }]
            })
          } else {
            data[index].children.push({
              value: res[i].Id,
              label: res[i].Name,
            })
          }
        }
        this.cuisine = data
      })
      this.$post('admin/listFactory', {}).then(res => {
        this.factoryList = res.content
      })
      this.$get("admin/listMaintainer").then((res) => {
        this.maintenanceList = res;
      });
      var data = [{
        label: '监测类',
        options: []
      }, {
        label: '非监测类',
        options: []
      }]
      this.$get('admin/locale_type', {}).then(res => {
        for (var i = 0; i < res.list.length; i++) {
          if (res.list[i].type == 1) {
            res.list[i].typeName = "监测类"
            data[0].options.push({
              value: res.list[i].id,
              label: res.list[i].name
            })
          } else {
            data[1].options.push({
              value: res.list[i].id,
              label: res.list[i].name
            })
          }
        }
        this.localeTypes = data
      })
    },
    handleEditDialogClose: function () {
      if (this.changeState) {
        this.changeState = false
      }
      this.handleClose()
    },
    hasChange() {
      this.changeState = true
    },
    handlePaste() {
      this.changeState = true
      const arr = this.formData.lng.split(',')
      this.formData.lng = arr[0]
      this.formData.lat = arr[1]
    },
    handleOwnerChange(v) {
      this.changeState = true
    },
    /**
     * 监测仪品牌选择后调用
     * @param value
     */
    handleFactorySelect(value) {
      this.changeState = true
      this.formData.voc_factory_model_id = ""
      let param = {
        StartAt: 0,
        Size: 20,
        Param:
          {
            FactoryName: value,
            ModelType: 1
          }
      }
      this.$post('admin/listFactoryModel', param).then(res => {
        this.factoryModelList = res.content
      })
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    removeImg(idx) {
      this.changeState = true
      this.fileList.splice(idx, 1)
    },
    handleAvatarSuccess(res, file) {
      this.fileList.push(res.Data)
    },
    handleSubmit() {
      if (!this.changeState && this.title !== '审核') {
        this.$emit("handleClose")
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              const para = Object.assign({}, this.formData)
              if (para.id) {
                para.locale_id = para.id
              }
              let userInfo = getUserInfo().Info
              para.owner = ownerJoin(this.formData.owner, userInfo)
              para.area_id = join(para.area_id)
              para.pics = this.fileList
              let param = new URLSearchParams()

              Object.entries(para).forEach(([key, val]) => {
                if (key == 'cuisine') {
                  if (val.length > 1) {
                    param.append(key, val[1])
                  } else {
                    this.$message({
                      message: '菜系选择有误',
                      type: 'info'
                    });
                    return
                  }
                } else {
                  if (key != "type") {
                    param.append(key, val)
                  }  
                }
              })
              if (this.title === '新增') {
                this.$post('admin/locale/add', param).then(res => {
                  this.$message({
                    message: '添加成功',
                    type: 'success'
                  });
                  this.$emit("handleClose")
                })
              } else if (this.title === '编辑') {
                this.$post('admin/locale/update', param).then(res => {
                  this.$message({
                    message: '修改成功',
                    type: 'success'
                  });
                  this.$emit("handleClose")
                })
              }
            })
        }
      })
    },
    handleClose() {
      this.$emit("handleClose")
    },
    handleFactoryModelChange(val) {
      this.$forceUpdate()
    },
    statusChange(val) {
      if (val === "正常") {
        this.formData.status_of_record = "NORMAL"
      } else if (val === "下线") {
        this.formData.status_of_record = "OFFLINE"
      } else if (val === "歇业") {
        this.formData.status_of_record = "REST"
      }
    },
    // imgExceed(files, fileList) {
    //   this.$message({
    //     type: "error",
    //     message: "图片上传数目超过限制！"
    //   })
    // }
  }
}
</script>
<style scoped lang="scss">
.form-part {
  display: flex;
  padding: 20px 0 20px 0;
  margin-bottom: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  border-color: #eeeeee;
}

.form-part-title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #EBEEF5
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.single-img > img {
  width: 100px;
  height: 100px;
}


.wordWrapClass {
  vertical-align: center;
  writing-mode: vertical-lr;
  text-align: center;
  letter-spacing: 6px;
  font-weight: bold;

}
</style>
