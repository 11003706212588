<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      title="批量导入"
      @open="importList=[]"
      @close="handleDialogClose"
      width="50%">
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-cascader
            placeholder="所属单位"
            v-model="owner"
            :options="customerTree"
            :props="customerProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-upload
          action="#"
          name="file"
          ref="upload"
          multiple
          accept=".xls,.xlsx"
          :on-change="handleChange"
          :auto-upload="false"
          :show-file-list="false"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form>
      <el-table
        empty-text="暂未上传文件"
        :data="importList">
        <el-table-column
          prop="file_name"
          header-align="center"
          width="300"
          label="文件名"/>
        <el-table-column
          header-align="center"
          align="center"
          prop="created_at"
          label="上传时间"/>
        <el-table-column
          prop="status"
          header-align="center"
          align="center"
          label="上传情况">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="warning">待上传</el-tag>
            <el-tag v-else-if="scope.row.status === 100" type="success">上传成功</el-tag>
            <el-progress type="circle" v-else :percentage="scope.row.status" :status='scope.row.status ===75? "warning":""' width="30"></el-progress>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" slot="reference" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="handleDialogClose"
        >取消
        </el-button
        >
        <el-button type="primary" size="mini" @click.native="handleSubmit"
        >提交
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import conf from "@/config";
import dayjs from "dayjs";
import {mapState} from 'vuex'
import {ownerJoin, getUserInfo} from '@/util/index'


export default {
  name: "LocaleBatchImportDialog",
  props: ['visible'],
  data() {
    return {
      importList: [],
      total: 0,
      deleteVisible: false,
      owner: "",
      userInfo: {},
    }
  },
  created(){
    try {
      const user = getUserInfo()
      this.userInfo = user.Info
    } catch (e) {
      this.$message.error(e)
    }
    this.owner = ""
  },
  computed: {
    ...mapState(['customerTree']),
    ...mapState({
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true}),
    })
  },
  methods: {
    handleDelete(row) {
      this.importList.splice(this.importList.indexOf(row), 1)
    },
    handleChange(file) {
      let item = {
        file_name: file.name,
        status: 0,
        created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        file: file.raw
      }
      this.importList.unshift(item)
    },
    handleDialogClose() {
      this.$emit("handleClose")
    },
    handleSubmit() {
      if (this.owner == "") {
        this.$message({
        type: "error",
        message: "请先选择所属单位！"
      })
        return
      }
      for (let item of this.importList) {
        if (item.status === 100)
          continue
        let fd = new FormData()
        fd.append('file', item.file)
        fd.append('owner', `${ownerJoin(this.owner, this.userInfo)}`)
        item.status = 50
        this.$post('admin/uploadLocale/add', fd).then(res => {
          item.status = 100
        }).catch(e => {
          this.$message({
            type: 'error',
            message: e.response.data
          })
          item.status = 75
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">

</style>
